import { default as _91_46_46_46slug_93UHPkUyxDwtMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue?macro=true";
import { default as airport_45hotels_45with_45parkingRP6lGZNqlLMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as airport_45parkingHmTWwFCpcWMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-parking.vue?macro=true";
import { default as port_45hotels_45with_45parkingaxV1NFaTY7Meta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as port_45parkinggvZcgIdTarMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-parking.vue?macro=true";
import { default as healthvL3iYEJVzMMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/health.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93F5ot9e5YguMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93kmZWYGqJ8tMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93iECUfhJ4RmMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stubspgkrsaiqTMeta } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubspgkrsaiqT } from "/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.2_eslint@8.57.1_ioredis@5.4.1_magicast_oxk35v6eghyt73b6npjqw3pzv4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/pages/[...slug].vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/[IATA]/port-parking.vue")
  },
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/health.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src1478321061/src/bitbucket.org/magusa/cavu-workspaces/apps/psf/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: component_45stubspgkrsaiqTMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubspgkrsaiqT
  }
]